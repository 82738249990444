<template>
    <div class="d-flex mb-3">
        <button type="button" class="btn btn-outline-dark waves-effect waves-light py-2 px-4" data-bs-toggle="modal"
                data-bs-target=".add-month-fund"><span class="d-block d-sm-none">+</span>
            <span class="d-none d-sm-inline-block text-uppercase font-size-12">Распределение прибыли и фондов</span></button>
        <div class="ms-auto">
            <FilterMTMComponent :search = search :formFilter="formFilter" :filter_mtm="filter_mtm" />
        </div>
    </div>

    <div v-if="!!fund_visibility.length" class="row">
        <h6>{{!!fund_visibility.length && !!fund_visibility_2.length ? filter_mtm.select_year + '/ 1' : ''}}</h6>
        <div v-for="fund in fund_visibility" class="col-xxl-3 col-xxl-mac-3 col-xl-3 col-md-6">
            <div class="card">
                <div class="card-body border">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <p class="text-truncate font-size-14 mb-1 text-uppercase text-primary">{{fund.name}}</p>
                            <h4 class="mb-1">
                                {{formatNumber(Math.round(fund.start_total + sumFundReceived(fund) - sumFundMonth(fund)))}}
                                <i class="mdi mdi-currency-eur"></i></h4>
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-light text-primary rounded-3">
                                <i class="mdi mdi-currency-eur font-size-24 text-white"></i>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex font-size-12 mb-1">
                        <div>Поступило: <span class="text-success"><i class="mdi font-size-14 mdi-menu-up"></i>{{formatNumber(Math.round(sumFundReceived(fund)))}} <i class="mdi mdi-currency-eur"></i></span></div>
<!--                        <div class="ms-auto">Потрачено: {{formatNumber(fund.spent)}}</div>-->
                        <div class="ms-auto">Потрачено: <span class="text-danger"><i class="mdi font-size-14 mdi-menu-down"></i>{{formatNumber(Math.round(sumFundMonth(fund)))}} <i class="mdi mdi-currency-eur"></i></span></div>
                    </div>
                </div><!-- end cardbody -->
            </div><!-- end card -->
        </div><!-- end col -->

        <div v-if="total.total > 0" class="col-xxl-3 col-xxl-mac-3 col-xl-3 col-md-6">
            <div class="card">
                <div class="card-body total">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <p class="text-truncate font-size-14 mb-1 text-uppercase text-primary">Итого</p>
                            <h4 class="mb-1">{{formatNumber(total.total)}} <i class="mdi mdi-currency-eur"></i></h4>
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-light text-primary rounded-3">
                                <i class="mdi mdi-currency-eur font-size-24 text-white"></i>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex font-size-12 mb-1">
                        <div>Поступило: <span class="text-success"><i class="mdi font-size-14 mdi-menu-up"></i>{{formatNumber(total.received)}} <i class="mdi mdi-currency-eur"></i></span></div>
                        <div class="ms-auto">Потрачено: <span class="text-danger"><i class="mdi font-size-14 mdi-menu-down"></i>{{formatNumber(total.spent)}} <i class="mdi mdi-currency-eur"></i></span></div>
                    </div>
                </div><!-- end cardbody -->
            </div><!-- end card -->
        </div><!-- end col -->
    </div>

    <div v-if="!!fund_visibility_2.length" class="row">
        <h6>{{!!fund_visibility.length && !!fund_visibility_2.length ? filter_mtm.select_year + '/ 2' : ''}}</h6>
        <div v-for="fund in fund_visibility_2" class="col-xxl-3 col-xxl-mac-3 col-xl-3 col-md-6">
            <div class="card">
                <div class="card-body border">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <p class="text-truncate font-size-14 mb-1 text-uppercase text-primary">{{fund.name}}</p>
                            <h4 class="mb-1">
                                {{formatNumber(Math.round(fund.start_total + sumFundReceived2(fund) - sumFundMonth(fund)))}}
                                <i class="mdi mdi-currency-eur"></i></h4>
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-light text-primary rounded-3">
                                <i class="mdi mdi-currency-eur font-size-24 text-white"></i>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex font-size-12 mb-1">
                        <div>Поступило: <span class="text-success"><i class="mdi font-size-14 mdi-menu-up"></i>{{formatNumber(Math.round(sumFundReceived2(fund)))}} <i class="mdi mdi-currency-eur"></i></span></div>
                        <!--                        <div class="ms-auto">Потрачено: {{formatNumber(fund.spent)}}</div>-->
                        <div class="ms-auto">Потрачено: <span class="text-danger"><i class="mdi font-size-14 mdi-menu-down"></i>{{formatNumber(Math.round(sumFundMonth(fund)))}} <i class="mdi mdi-currency-eur"></i></span></div>
                    </div>
                </div><!-- end cardbody -->
            </div><!-- end card -->
        </div><!-- end col -->

        <div v-if="total.total_2 > 0" class="col-xxl-3 col-xxl-mac-3 col-xl-3 col-md-6">
            <div class="card">
                <div class="card-body total">
                    <div class="d-flex">
                        <div class="flex-grow-1">
                            <p class="text-truncate font-size-14 mb-1 text-uppercase text-primary">Итого</p>
                            <h4 class="mb-1">{{formatNumber(total.total_2)}} <i class="mdi mdi-currency-eur"></i></h4>
                        </div>
                        <div class="avatar-sm">
                            <span class="avatar-title bg-light text-primary rounded-3">
                                <i class="mdi mdi-currency-eur font-size-24 text-white"></i>
                            </span>
                        </div>
                    </div>
                    <div class="d-flex font-size-12 mb-1">
                        <div>Поступило: <span class="text-success"><i class="mdi font-size-14 mdi-menu-up"></i>{{formatNumber(total.received_2)}} <i class="mdi mdi-currency-eur"></i></span></div>
                        <div class="ms-auto">Потрачено: <span class="text-danger"><i class="mdi font-size-14 mdi-menu-down"></i>{{formatNumber(total.spent_2)}} <i class="mdi mdi-currency-eur"></i></span></div>
                    </div>
                </div><!-- end cardbody -->
            </div><!-- end card -->
        </div><!-- end col -->
    </div>

    <AddModalFundsComponent :filter_mtm="filter_mtm" :funds="funds" :total="total" :list_month="list_month" @search_fund="search" />
</template>

<script>
import FilterMTMComponent from "../ui/FilterMTMComponent.vue";
import AddModalFundsComponent from "../ui/AddModalFundsComponent.vue";
import axios from "axios";
export default {
    name: "FundsComponent",
    props:['last_date'],
    components:{FilterMTMComponent,AddModalFundsComponent},
    data:()=>({
        formFilter: {
            filter_period_start:null,
            filter_period_end:null,
        },
        filter_mtm: {
            mtm: true,
            select_month: '',//new Date().getMonth(),
            select_year: '',//new Date().getFullYear(),
        },
        funds:[],
        fund_visibility:[],
        fund_visibility_2:[],

        total:{
            received:0,
            spent:0,
            total:0,
            fund_profit:0,
            fund_sinking:0,

            received_2:0,
            spent_2:0,
            total_2:0,
            fund_profit_2:0,
            fund_sinking_2:0,

            start_all:0,
            received_all:0,
            spent_all:0,
            total_all:0,
        },
        list_month: [{ru: 'Январь',en:'january'},
                     {ru: 'Февраль',en:'february'},
                     {ru: 'Март',en:'march'},
                     {ru: 'Апрель',en:'april'},
                     {ru: 'Май',en:'may'},
                     {ru: 'Июнь',en:'june'},
                     {ru: 'Июль',en:'july'},
                     {ru: 'Август',en:'august'},
                     {ru: 'Сентябрь',en:'september'},
                     {ru: 'Октябрь',en:'october'},
                     {ru: 'Ноябрь',en:'november'},
                     {ru: 'Декабрь',en:'december'}],
    }),
    created() {
        this.filter_mtm.select_year = this.last_date['year']
        this.filter_mtm.select_month = this.last_date['month']
    },
    // watch: {
    //     'filter_mtm.select_year': function (val, oldVal) {
    //         if(val < new Date().getFullYear() && val < oldVal){
    //             this.filter_mtm.select_month = 11
    //         }
    //     },
    // },
    methods: {
        search() {
            axios.post('/analytics/funds/search-funds',this.filter_mtm).then(response => {
                this.funds = response.data['funds']
                let funds = [];
                let funds_2 = [];

                if(this.filter_mtm.select_year === 2024){
                    funds = this.funds.filter(x => x && x.visibility === '1' && x.month_to === 5)
                    funds_2 = this.funds.filter(x => x && x.visibility === '1' && x.month_to === 11)
                }else{
                    funds = this.funds.filter(x => x && x.visibility === '1')
                }

                //month_from month_to думать

                // this.filter_mtm.select_year = response.data['last_date']['year']
                // this.filter_mtm.select_month = response.data['last_date']['month']

                this.fund_visibility = this.funds.filter(x => x && x.id_found === 1 && x.month_to === (this.filter_mtm.select_year === 2024 ? 5 : 11)).map(x => x[this.list_month[this.filter_mtm.select_month].en]) > 0 ? funds : []
                this.total.fund_profit = this.funds.filter(x => x.id_found === 1 && x.month_to === (this.filter_mtm.select_year === 2024 ? 5 : 11)).map(x => this.sumFundMonth(x))[0];
                this.total.fund_sinking = this.funds.filter(x => x.id_found === 2 && x.month_to === (this.filter_mtm.select_year === 2024 ? 5 : 11)).map(x => this.sumFundMonth(x))[0];
                this.total.received = Math.round(this.fund_visibility.reduce((partialSum, a) => partialSum + this.sumFundReceived(a), 0));
                this.total.spent = Math.round(this.fund_visibility.reduce((partialSum, a) => partialSum + this.sumFundMonth(a), 0));
                this.total.total = Math.round(this.fund_visibility.reduce((partialSum, a) => partialSum + (a.start_total + this.sumFundReceived(a) - this.sumFundMonth(a)), 0));

                let v2 = this.funds.filter(x => x && x.id_found === 1 && x.month_to === 11).map(x => x[this.list_month[this.filter_mtm.select_month].en])

                this.fund_visibility_2 = v2 > 0 || v2 < 0  ? funds_2 : []
                this.total.fund_profit_2 = this.funds.filter(x => x.id_found === 1 && x.month_to === 11).map(x => this.sumFundMonth(x))[0];
                this.total.fund_sinking_2 = this.funds.filter(x => x.id_found === 2 && x.month_to === 11).map(x => this.sumFundMonth(x))[0];
                this.total.received_2 = Math.round(this.fund_visibility_2.reduce((partialSum, a) => partialSum + this.sumFundReceived2(a), 0));
                this.total.spent_2 = Math.round(this.fund_visibility_2.reduce((partialSum, a) => partialSum + this.sumFundMonth(a), 0));
                this.total.total_2 = Math.round(this.fund_visibility_2.reduce((partialSum, a) => partialSum + (a.start_total + this.sumFundReceived2(a) - this.sumFundMonth(a)), 0));


                //ИТОГО
                this.total.start_all = Math.round(funds.reduce((partialSum, a) => partialSum + a.start_total, 0));
                this.total.received_all = Math.round(funds.reduce((partialSum, a) => partialSum + this.sumFundReceived(a), 0));
                this.total.spent_all = Math.round(funds.reduce((partialSum, a) => partialSum + this.sumFundMonth(a), 0));
                this.total.total_all = Math.round(funds.reduce((partialSum, a) => partialSum + (a.start_total + this.sumFundReceived(a) - this.sumFundMonth(a)), 0));

                this.total.start_all_2 = Math.round(funds_2.reduce((partialSum, a) => partialSum + a.start_total, 0));
                this.total.received_all_2 = Math.round(funds_2.reduce((partialSum, a) => partialSum + this.sumFundReceived2(a), 0));
                this.total.spent_all_2 = Math.round(funds_2.reduce((partialSum, a) => partialSum + this.sumFundMonth(a), 0));
                this.total.total_all_2 = Math.round(funds_2.reduce((partialSum, a) => partialSum + (a.start_total + this.sumFundReceived2(a) - this.sumFundMonth(a)), 0));

            }).finally(() => {
                this.preloaderClose()
            })
        },
        sumFundReceived(fund){
            return (fund.id_found === 3 ? this.total.fund_sinking :
                (fund.percent ? (this.total.fund_profit / 100 * fund.percent) : 0)
            )
        },
        sumFundReceived2(fund){
            return (fund.id_found === 3 ? this.total.fund_sinking_2 :
                    (fund.percent ? (this.total.fund_profit_2 / 100 * fund.percent) : 0)
            )
        },
        sumFundMonth(fund){
            this.preloaderShow()
            if(fund){
                return ((this.filter_mtm.select_month >= 0 ? fund.january : 0) +
                    (this.filter_mtm.select_month >= 1 ? fund.february : 0) +
                    (this.filter_mtm.select_month >= 2 ? fund.march : 0) +
                    (this.filter_mtm.select_month >= 3 ? fund.april : 0) +
                    (this.filter_mtm.select_month >= 4 ? fund.may : 0) +
                    (this.filter_mtm.select_month >= 5 ? fund.june : 0) +
                    (this.filter_mtm.select_month >= 6 ? fund.july : 0) +
                    (this.filter_mtm.select_month >= 7 ? fund.august : 0) +
                    (this.filter_mtm.select_month >= 8 ? fund.september : 0) +
                    (this.filter_mtm.select_month >= 9 ? fund.october : 0) +
                    (this.filter_mtm.select_month >= 10 ? fund.november : 0) +
                    (this.filter_mtm.select_month === 11 ? fund.december : 0))
            }
            this.preloaderClose()
        }
    }
}
</script>

<style scoped>
</style>
